<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>惠豆商城</el-breadcrumb-item>
          <el-breadcrumb-item>商品管理</el-breadcrumb-item>
          <el-breadcrumb-item>产品列表</el-breadcrumb-item>
          <el-breadcrumb-item>添加商品</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-form label-width="100px" ref="info" :model="info">
        <div class="admin_main_block_top">
          <div class="admin_main_block_right">
            <div>
              <el-row>
                <el-col :span="12">
                  <el-button type="primary" @click="submitForm('info')"
                  >发布产品
                  </el-button
                  >
                </el-col>
                <el-col :span="12">
                  <el-button icon="el-icon-back" @click="$router.go(-1)"
                  >返回
                  </el-button
                  >
                </el-col>
              </el-row>
            </div>
          </div>
        </div>

        <div class="admin_form_main">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">
              <el-form-item
                  label="商品名"
                  :rules="[
                  {
                    required: true,
                    message: '商品名不能为空',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                    placeholder="请输入商品名"
                    v-model="info.name"
                ></el-input>
              </el-form-item>
              <el-form-item
                  label="商品类型"
                  class="width_auto"
                  :rules="[
                  {
                    required: true,
                    message: '请选择商品类型',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                    v-model="info.mainType"
                    placeholder="请选择"
                >
                  <el-option
                      key="10"
                      label="惠豆商城"
                      value="10"
                  ></el-option>
                  <el-option
                          key="40"
                          label="共享商城"
                          value="40"
                  ></el-option>
                  <el-option
                      key="20"
                      label="精品馆"
                      value="20"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  :hidden="isHide()"
                  label="商品类别"
                  class="width_auto"
                  :rules="[
                  {
                    required: true,
                    message: '请选择商品类别',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select
                    v-model="info.categoryId"
                    placeholder="请选择">
                  <el-option
                      v-for="(v, k) in class_list"
                      :key="k"
                      :label="v.categoryName"
                      :value="v.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="商品主图" class="width_auto">
                <el-upload
                    action=""
                    :headers="upload_headers"
                    list-type="picture-card"
                    ref="upload"
                    :file-list="file_list"
                    :limit="5"
                    :multiple="true"
                    :before-upload="beforeAvatarUpload"
                    :http-request="uploadFile"
                    :on-success="handleAvatarSuccess"
                    :on-exceed="onExceed"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                        class="el-upload-list__item-thumbnail"
                        :src="file.url"
                        alt=""
                    />
                    <div class="is_master" :style="getMasterImage(file)">
                      <i class="el-icon-finished"> 主图</i>
                    </div>
                    <span class="el-upload-list__item-actions">
                      <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="setMaster(file)"
                      >
                        <i class="el-icon-finished"></i>
                      </span>
                      <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span
                          v-if="!disabled"
                          class="el-upload-list__item-delete"
                          @click="handleRemove(file)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="运费物流">
                <div class="freight_chose">
                  <el-radio v-model="info.freight" :label="0"
                  >固定运费
                  </el-radio
                  >
                  <el-radio v-model="info.freight" :label="1">免运费</el-radio>
                </div>
                <!--<div class="fixed_freight" v-show="info.freight==0">
                                    <el-input type="number" v-model="info.freight" placeholder="0.00">
                                        <template slot="append">￥</template>
                                    </el-input>
                                </div>-->
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="规格" name="second">
              <sku-data
                  @get-sku-data="getSkuData"
                  v-if="secondIs"
                  :skuData="info.commoditySpec"
                  :goodsSkuDTOS="info.goodsSkuDTOS"
                  :main-type="info.mainType"
              ></sku-data>
            </el-tab-pane>
            <el-tab-pane label="详情" name="third">
              <el-form-item label="商品详情" class="width_auto_70">
                <wangeditor
                    @goods-content="goodsContent"
                    :contents="info.commodityDetail"
                ></wangeditor>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import wangeditor from "@/components/seller/wangeditor.vue";
import skuData from "@/components/sku-page.vue";
import {baseUrl, staticUrl} from "@/plugins/api";

export default {
  name: "qingwu",
  components: {
    wangeditor,
    skuData,
  },
  props: {},
  data() {
    return {
      info: {
        mainType: '10',
        categoryId: "",
        freight: 1,
        list: [],
      },
      actionUploadUrl: "",
      class_list: [],
      upload_headers: {},
      file_list: [],
      dialogTableVisible: false, // 选择规格打开
      dialogVisible: false, // 是否打开预览
      dialogImageUrl: "", // 预览
      disabled: false,
      activeName: "first",
      /*定义前一个活动Tab,初始化默认为 ActiveTab */
      oldTab: "add",
      /*定义Tab是否加载-第一个默认加载 */
      secondIs: false,
      upload: {
        param: {fileType: "mcategory"},
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    isHide() {
      if (this.info.mainType == '20') {
        return 'hidden';
      } else {
        return null;
      }
    },
    resetForm(e) {
      this.$refs[e].resetFields();
    },
    submitForm(e) {
      // 验证表单
      this.$refs[e].validate((res) => {
        if (res) {
          let img = [];
          let isCommit = true;
          if (!this.info.commodityShowImage) {
            this.$message({
              type: "warning",
              message: "请设置主图片！",
            });
            return;
          }

          // 图片 验证
          if (this.file_list.length <= 0) {
            return this.$message.error("商品主图至少上传一张");
          }
          this.file_list.forEach((rs) => {
            img.push(rs.url);
          });
          this.info.commodityMainImage = img; // 图片

          if (!this.info.list) {
            return this.$message.error("请选择规格");
          }
          // 判断是否填写完整
          if (this.info.list.length > 0) {
            // 验证规格属性是否填写正确
            this.info.list.forEach((rs) => {
              if (
                  this.isEmpty(rs.exchangeHuiDouNum) ||
                  this.isEmpty(rs.salePrice) ||
                  this.isEmpty(rs.stock)
              ) {
                console.log(rs);
                console.log(this.isEmpty(rs.exchangeHuiDouNum));
                console.log(this.isEmpty(rs.salePrice));
                console.log(this.isEmpty(rs.stock));
                isCommit = false;
                return this.$message.error("请填写完整的规格");
              }
            });
          }
          if (
              this.info.goods_price <= 0 ||
              this.info.goods_market_price <= 0 ||
              this.info.goods_num <= 0
          ) {
            isCommit = false;
            return this.$message.error("价格或库存没填写，或者填写错误！");
          }
          if (isCommit) {
            this.add_goods();
          }
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },

    isEmpty(a) {
      console.log(a);
      if (a == "" || a == null || a == undefined) {
        // "",null,undefined
        return false;
      }
      return false;
    },
    handleAvatarSuccess() {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file;
      this.dialogVisible = true;
    },
    // 文件删除
    handleRemove(file) {
      // console.log(file,this.$refs.upload);
      this.$refs.upload.handleRemove(file);
      let index = this.file_list.indexOf(file);
      this.file_list.splice(index, 1);
    },
    // 设置主图
    setMaster(file) {
      this.info.commodityShowImage = file.url;
    },
    // 上传超过限制
    onExceed() {
      this.$message.error("商品展示图片不能超过5个");
    },
    getMasterImage(file) {
      if (file.url != undefined) {
        if (file.url == this.info.commodityShowImage) {
          return "display:block";
        } else {
          return "display:none";
        }
      }
    },
    // 富文本编辑内容变化
    goodsContent(res) {
      this.info.commodityDetail = res;
    },
    // 添加商品
    add_goods() {
      let info = this.info;
      // 请求
      // eslint-disable-next-line no-unreachable
      this.$post(this.$api.addIntegralCommodity, info).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "添加成功！",
          });
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(tab) {
      if (tab.name + "Is" == "secondIs") {
        this[tab.name + "Is"] = true;
        if (tab.name != this.oldTab) {
          this[this.oldTab + "Is"] = false;
          this.oldTab = tab.name;
        }
      }
    },
    // 获取添加初始化信息
    get_goods_add_info() {
      let id = this.$route.query.id;
      this.$postFormData(this.$api.commodityDetail, {commodityId: id}).then(
          (res) => {
            if (res.code === 1) {
              this.info = res.data;
              // 图片
              this.info.goodsPictureList.forEach((rs, index) => {
                this.file_list.push({name: index, url: rs.picturePath});
              });
              //详情
              this.info.commodityDetail = res.data.goodsDetail.detailContent;
              this.info.commodityShowImage =
                  res.data.commodityGoodsPicture.picturePath;
            }
          }
      );
    },
    getSkuData(val) {
      console.log(val,6666)
      this.info.list = val.list;
      this.info.propertyList = val.propertyList;
    },
    getGoodsClass() {
      let self = this;
      this.$post(this.$api.goodscategory, {
        page: 0,
        limit: 1000,
        parentId: 0,
      }).then((res) => {
        if (res.code === 1) {
          self.class_list = res.data.data;
        }
      });
    },
    uploadFile(option) {
      const formData = new FormData();
      formData.append("file", option.file);
      formData.append("fileType", this.upload.param.fileType);
      this.$postMultipart(this.$api.upload, formData).then((res) => {
        if (res.code === 1) {
          this.file_list.push({
            name: "",
            url: `${staticUrl}${res.data.filePath}${res.data.fileName}`,
          });
          this.$forceUpdate();
        }
      });
    },
    beforeAvatarUpload(file) {
      //文件上传之前调用做一些拦截限制
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
  },
  created() {
    this.actionUploadUrl = baseUrl + "system/file/upload";
    this.getGoodsClass();
    this.get_goods_add_info();
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  line-height: 32px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 146px;
  height: 146px;
  line-height: 146px;
  text-align: center;
}

.avatar-upload {
  width: 146px;
  height: 146px;
}

.is_master {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  width: 164px;
  text-align: center;
  display: none;
}

.goods_class_add_left {
  float: left;
  margin-right: 15px;
  // border:1px solid #e1e1e1;
  padding: 0 20px;
  border-radius: 5px;
  background: #f1f1f1;
}

.el-icon-delete {
  cursor: pointer;
}

.el-icon-delete:hover {
  color: red;
}
</style>
