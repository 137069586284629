var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qingwu"},[_c('div',{staticClass:"admin_main_block"},[_c('div',{staticClass:"admin_breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/Admin/index' }}},[_vm._v("首页 ")]),_c('el-breadcrumb-item',[_vm._v("惠豆商城")]),_c('el-breadcrumb-item',[_vm._v("商品管理")]),_c('el-breadcrumb-item',[_vm._v("产品列表")]),_c('el-breadcrumb-item',[_vm._v("添加商品")])],1)],1)]),_c('div',{staticClass:"admin_main_block admin_m15"},[_c('el-form',{ref:"info",attrs:{"label-width":"100px","model":_vm.info}},[_c('div',{staticClass:"admin_main_block_top"},[_c('div',{staticClass:"admin_main_block_right"},[_c('div',[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('info')}}},[_vm._v("发布产品 ")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-button',{attrs:{"icon":"el-icon-back"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回 ")])],1)],1)],1)])]),_c('div',{staticClass:"admin_form_main"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"基本信息","name":"first"}},[_c('el-form-item',{attrs:{"label":"商品名","rules":[
                {
                  required: true,
                  message: '商品名不能为空',
                  trigger: 'blur',
                } ]}},[_c('el-input',{attrs:{"placeholder":"请输入商品名"},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}})],1),_c('el-form-item',{staticClass:"width_auto",attrs:{"label":"商品类型","rules":[
                {
                  required: true,
                  message: '请选择商品类型',
                  trigger: 'blur',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.info.mainType),callback:function ($$v) {_vm.$set(_vm.info, "mainType", $$v)},expression:"info.mainType"}},[_c('el-option',{key:"10",attrs:{"label":"惠豆商城","value":"10"}}),_c('el-option',{key:"40",attrs:{"label":"共享商城","value":"40"}}),_c('el-option',{key:"20",attrs:{"label":"精品馆","value":"20"}})],1)],1),_c('el-form-item',{staticClass:"width_auto",attrs:{"hidden":_vm.isHide(),"label":"商品类别","rules":[
                {
                  required: true,
                  message: '请选择商品类别',
                  trigger: 'blur',
                } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.info.categoryId),callback:function ($$v) {_vm.$set(_vm.info, "categoryId", $$v)},expression:"info.categoryId"}},_vm._l((_vm.class_list),function(v,k){return _c('el-option',{key:k,attrs:{"label":v.categoryName,"value":v.id}})}),1)],1),_c('el-form-item',{staticClass:"width_auto",attrs:{"label":"商品主图"}},[_c('el-upload',{ref:"upload",attrs:{"action":"","headers":_vm.upload_headers,"list-type":"picture-card","file-list":_vm.file_list,"limit":5,"multiple":true,"before-upload":_vm.beforeAvatarUpload,"http-request":_vm.uploadFile,"on-success":_vm.handleAvatarSuccess,"on-exceed":_vm.onExceed},scopedSlots:_vm._u([{key:"file",fn:function(ref){
              var file = ref.file;
return _c('div',{},[_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":file.url,"alt":""}}),_c('div',{staticClass:"is_master",style:(_vm.getMasterImage(file))},[_c('i',{staticClass:"el-icon-finished"},[_vm._v(" 主图")])]),_c('span',{staticClass:"el-upload-list__item-actions"},[(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.setMaster(file)}}},[_c('i',{staticClass:"el-icon-finished"})]):_vm._e(),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handlePictureCardPreview(file)}}},[_c('i',{staticClass:"el-icon-zoom-in"})]):_vm._e(),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()])])}}])},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])],1),_c('el-form-item',{attrs:{"label":"运费物流"}},[_c('div',{staticClass:"freight_chose"},[_c('el-radio',{attrs:{"label":0},model:{value:(_vm.info.freight),callback:function ($$v) {_vm.$set(_vm.info, "freight", $$v)},expression:"info.freight"}},[_vm._v("固定运费 ")]),_c('el-radio',{attrs:{"label":1},model:{value:(_vm.info.freight),callback:function ($$v) {_vm.$set(_vm.info, "freight", $$v)},expression:"info.freight"}},[_vm._v("免运费")])],1)])],1),_c('el-tab-pane',{attrs:{"label":"规格","name":"second"}},[(_vm.secondIs)?_c('sku-data',{attrs:{"skuData":_vm.info.commoditySpec,"goodsSkuDTOS":_vm.info.goodsSkuDTOS,"main-type":_vm.info.mainType},on:{"get-sku-data":_vm.getSkuData}}):_vm._e()],1),_c('el-tab-pane',{attrs:{"label":"详情","name":"third"}},[_c('el-form-item',{staticClass:"width_auto_70",attrs:{"label":"商品详情"}},[_c('wangeditor',{attrs:{"contents":_vm.info.commodityDetail},on:{"goods-content":_vm.goodsContent}})],1)],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }